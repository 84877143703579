/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'swords': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.384 8.698a.571.571 0 01.801 0l4.79 4.73a.554.554 0 010 .79.571.571 0 01-.801 0L1.384 9.49a.554.554 0 010-.79z" _fill="#FAFBFC"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M3.582 10.02l2.126 2.098 5.92-5.847.798-2.887-2.923.789-5.92 5.847zm-1.2-.396l6.426-6.347a.568.568 0 01.252-.144l4.024-1.085c.423-.114.811.269.696.686l-1.1 3.975a.558.558 0 01-.145.248l-6.427 6.348a.571.571 0 01-.8 0l-2.927-2.89a.554.554 0 010-.79z" _fill="#FAFBFC"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M3.444 10.278a.554.554 0 010 .79l-2.2 2.173 1.063 1.049 2.2-2.172a.571.571 0 01.8 0 .554.554 0 010 .79l-2.2 2.173a1.142 1.142 0 01-1.6 0L.443 14.03a1.108 1.108 0 010-1.581l2.2-2.172a.571.571 0 01.8 0z" _fill="#FAFBFC"/><path pid="3" d="M9.848 12.118l2.125-2.098-.929-.918.809-.783 1.321 1.305a.554.554 0 010 .79l-2.926 2.89a.571.571 0 01-.8 0l-1.31-1.293.812-.779.898.886zM6.053 4.173l-2.924-.789.798 2.888.718.685-.823.777-.802-.777a.558.558 0 01-.145-.248l-1.1-3.975a.563.563 0 01.696-.686l4.025 1.085a.568.568 0 01.251.144l.711.703-.828.763-.577-.57z" _fill="#FAFBFC"/><path pid="4" fill-rule="evenodd" clip-rule="evenodd" d="M10.248 12.118a.571.571 0 01.8 0l2.111 2.084 1.063-1.05-2.11-2.084a.554.554 0 010-.79.571.571 0 01.8 0l2.11 2.084a1.108 1.108 0 010 1.581l-1.062 1.05a1.142 1.142 0 01-1.602 0l-2.11-2.085a.554.554 0 010-.79z" _fill="#FAFBFC"/><path pid="5" fill-rule="evenodd" clip-rule="evenodd" d="M8.51 14.219a.554.554 0 010-.791l4.79-4.73a.571.571 0 01.8 0 .554.554 0 010 .79l-4.79 4.73a.571.571 0 01-.8 0z" _fill="#FAFBFC"/>'
  }
})
